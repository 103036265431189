.contactForm {
  max-width: 600px;
  margin: 0 auto;
  padding: 30px;
  border: 5px dashed #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: -15px 15px 50px 20px #464646;
  margin-top: 50px;
}

header img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.contactForm h2 {
  text-align: center;
  margin-bottom: 20px;
}

.contactForm label {
  display: block;
  margin: 10px 0 5px;
}

.contactForm input,
.contactForm textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #000000;
  border-radius: 4px;
}

.contactForm button {
  width: 100%;
  padding: 10px;
  border: 1px solid #000000;
  border-radius: 4px;
  background-color: #ffc400;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.contactForm button:hover {
  background-color: #45a049;
}

footer{
  text-align: center;
  padding: 20px;
}

footer img{
  height: 50px;
  width: 50px;
  padding: 5px;
}

ul a, li{
  display: inline;
  padding: 10px;
  color: rgb(115, 128, 0);
  font-size: 1.2rem;
  @media (max-width: 550px){
    font-size: 0.5rem;
  }
  
}

ul a:hover{
  color: black;
}

ul a{
  text-decoration: none;
  border-bottom: 2px solid rgb(0, 0, 0);
}

ul{
  text-align: center;
}

header {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px;
  font-weight: bold;
  
}

.galleryImage {
  width: 400px; /* Adjust the width as needed */
  height: 100%; /* Maintain aspect ratio */
  margin: 0 auto;
}

.banner img{
  width: 100%;
  height: 100%;
  border-radius: 5px;
  margin-top: 20px;
  box-shadow: 0px -10px 20px #000000;
  @media (max-width: 550px){
    height: 200px;
  }
}


.banner h2{
  position: absolute;
  left: 0; 
  right: 0; 
  margin-inline: auto; 
  width: fit-content;
  color: rgb(255, 255, 255);
  font-size: 5rem;
  animation-name: h2anim;
  animation-duration: 4s;
  animation-fill-mode: both;
  @media (max-width: 550px){
    font-size: 2rem;
  }
}

@keyframes h2anim {
  0%   {transform: translateX(-2000px);}
  100% {transform: translateX(0px);}
}

.banner p{
  position: absolute;
  left: 0; 
  right: 0; 
  margin-inline: auto; 
  width: fit-content;
  top: 500px;
  color: rgb(255, 255, 255);
  font-size: 4rem;
  font-family: 'Times New Roman', Times, serif;
  animation-name: panim;
  animation-duration: 4s;
  animation-delay: 2s;
  animation-fill-mode: both;
  @media (max-width: 550px){
    font-size: 1rem;
    top: 250px;
  }
}

@keyframes panim {
  0%   {transform: translateX(-2000px);}
  100% {transform: translateX(0px);}
}

.banner{
  text-align: center;
}

table tr td{
  border: 2px dashed black;
  padding: 50px;
  @media (max-width: 550px){
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
    font-size: small;
    padding: 20px;
  }
}

table {
  border-collapse: separate;
  border-spacing: 50px 0;
  @media (max-width: 550px){
    border-collapse: separate;
    border-spacing: 5px 0;
    font-size: small;
  }
}

table,p {
  text-indent: 50px;
  word-spacing: 2px;
  line-height: 2;
  @media (max-width: 550px){
    text-indent: 5px;
    word-spacing: normal;
    line-height: normal;
  }
  }

table, td{
  padding: 20px 0;
}

footer, a{
  color: black;
  line-height: 1.8;
}

.kitchengif img{
  width: 50%;
  display: block;
margin-left: auto;
margin-right: auto;
}



